
/*
 * style sheet for modifying
 */
@media screen and (max-width: 1440px) {
    .test-detail-body {
        max-width: 80% !important;
    }
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
    .test-detail-body {
        max-width: 90% !important;
    }
}

@media screen and (max-width: 768px) {
    .flex-justify {
        display: block !important;
    }
    .detail-txt {
        padding: 15px 0 5px;
    }
    .detail-input {
        width: 100% !important;
    }
}

@media screen and (max-width: 600px) {
    .test-detail-body {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 414px) {
    .get-support {
        width: 100% !important;
        max-width: 80% !important;
        min-width: 170px;
    }
}

.justify-center {
    display: flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
}

.justify-left,
.flex-between {
    display: flex;
}

.flex-between {
    justify-content: space-between;
}

.prevent-text {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.mouse-cursor {
    cursor: pointer;
}

.test-detail-body {
    margin: 0 auto;
    width: 100%;
    max-width: 70%;
    text-align: left;
}

.flex-justify {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    align-items: center;
    transition: all 0.3s ease-out;
}
.detail-txt {
    width: 120px;
}
.detail-input {
    width: calc(100% - 120px);
}
.detail-input > div > input {
    width: 100%;
    outline: none;
}

.btn-common-radius {
    padding: 5px 20px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 15px center;

    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.get-support {
    margin: 30px auto 0;
    border: 2px solid gray;
    max-width: 190px;
    background-image: url("../images/get-support.svg");
    color: gray;
}
.get-support:hover {
    border: 2px solid #1376cc;
    color: #fff;
    background-color: #1376cc;
    background-image: url("../images/get-support-hover.svg");
}

/**
 * Modal
 */
.modal-body {
    position: fixed;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
    max-width: 400px;
    height: auto;
    min-height: 200px;
    top: 50%;
    left: 50%;
    color: #000;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
    z-index: 4000;
}

.modal-headers {
    padding: 20px 20px 0;
}
.modal-contents {
    padding: 0 20px;
}

.display-modal-block {
    display: block;
}

.display-modal-none {
    display: none;
}

.animate-modal-zoom {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #fff;
    animation:animatezoom 0.7s;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.65);
}

.language-form-select {
    margin: 100px;
    width: 5%;
    height: .25rem;
    font-size: 6px; }
  .alert-box {
    background-color: #fffbcc;
    color: #777;
    font-size: 14px;
    line-height: 23px;
    padding: 13px 16px;
    text-align: center; }

@keyframes animatezoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}




.input-row {
  position: relative;
  width: 100%;
  font-size: large;
  display: flex;
  align-items: stretch;
}

.input-row-wrapper {
  max-width: 800px;
  margin: 0 auto 20px;
}

.input-row .input-label {
  margin: 0;
}

.input-row__unit {
  position: absolute;
  top: 10px;
  right: 10px;
}

.input-row input {
  flex-grow: 1;
}
